.date {
    color: rgb(154, 151, 151);
    margin-bottom: 1rem;
}

.text {
    font-size: 25px;
    color: #337ab8;
}

.text2 {
    font-size: 25px;
}

.booktext {
    font-size: 15px;
}

.text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.small_hover:hover {
    text-decoration: underline;
    cursor: pointer;
}

.small {
    font-size: 20px;
    font-weight: 400;
}

.small_hover {
    font-size: 20px;
    font-weight: 600;
    color: #337ab8;
}

.sdate {
    color: rgb(154, 151, 151);
}