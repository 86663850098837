.text {
    font-size: 30px;
    font-weight: 600;
    display: flex;
    width: 98%;
}

.button {
    float: right;
}

.button:hover {
    background-color: #f3f3f3;
}

.container {
    padding-top: 30px;  
    display: flex;
    background-color: white;
    width: 100%;
    
}

.bottom {
    border-bottom: 1px solid;
    padding-bottom: 30px;  
    display: flex;
    width: 80%;
    margin: auto;
}