
.title {
    font-size: 25px;
}

.date {
    display: flex;
    float: left;
    margin-bottom: 1rem;
    width: 150px;
    float: left;
}

.link {
    display: flex;
    float: right;
    text-decoration: underline;
}

.link:hover {
    cursor: pointer;
}

.link2 {
    margin-left: 650px;
    text-decoration: underline;
    cursor: pointer;
}

.container {
    width: 100%;
    overflow: hidden;
}

.body {
    margin: auto;
    margin-top: 3rem;
    width: 90%;
}

.notes {
    margin-left: -4rem;
}

.image {
    width: 800px;
    height: 1200px;
    object-fit: cover;    
}