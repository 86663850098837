
.title {
    width: 60%;
    margin: auto;
    margin-top: 4rem;
    text-align: center;
}

.blogs {
    display: block;
    margin-top: 5rem;
    margin-bottom: -2rem;
}

.blogs_end {
    display: block;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.font { 
    margin-bottom: -2rem;
    font-family: 'Trebuchet MS', sans-serif;
} 

.bar {
    position: absolute;
    height: 100vh;
    right: 0;
    top: 0;
}

.main_normal {
    height: 100%;
    display: flex;
    margin-left: 10%;
}

.main_bar {
    height: 100%;
    display: flex;
    margin-left: 10%;
    opacity: 0.1;
}

.main_normal2 {
    height: 100%;
    display: flex;
}

.main_bar2 {
    height: 100%;
    display: flex;
    opacity: 0.1;
}

