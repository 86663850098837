.para {
    margin: auto;
    width: 60%;
    font-family: 'Gentium Plus', serif;
}

.profile {
    width: 300px;
    margin: auto;
}

.para2 {
    margin: auto;
    margin-left: 10%;
    width: 60%;
    font-family: 'Gentium Plus', serif;
    margin-top: 2rem;
}

.map {
    float: right;
    width: 50%;
    margin-top: 8rem;
}

.images {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10rem;
    margin-left: 1rem;
}


.github {
    width: 200px;
    display: table-cell;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
}


.bar {
    position: absolute;
    height: 100vh;
    right: 0;
    top: 0px;
}

.main_normal {
    height: 100%;
    display: flex;
    float: left;
    width: 100%;
}

.main_bar {
    height: 100%;
    display: flex;
    float: left;
    opacity: 0.1;

}

.website {
    display: table-cell;
    font-size: 20px;
    cursor: pointer;
}

.github:hover {
    text-decoration: underline;
    color: purple;
    cursor: pointer;
}

.website:hover {
    text-decoration: underline;
    color: purple;
    cursor: pointer;
}

.website {
    margin-left: 2rem;
    text-decoration: underline;
    display: flex;
    float: left;
}


.link {
    text-decoration: underline;
    display: flex;
    float: left;
}

.link2 {
    text-decoration: underline;
}

.link2:hover {
    color: purple;
    cursor: pointer;
}

.link:hover {
    color: purple;
    cursor: pointer;
}

.website:hover {
    color: purple;
    cursor: pointer;
}