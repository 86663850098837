.main {
    word-spacing: 50px;
    text-align: center;
}

.field {
    flex:1;
    color: #337ab8;
}

.field:hover {
    text-decoration: underline;
    color: rgb(206, 35, 206);
    cursor: pointer;
}
