.project {
    display: flex;
    float: left;
    margin-left: 2rem;
}

.title {
    padding-left: 2rem;
    display: inline-block;
    font-size: 30px;
    padding-top: 2rem;
}

.projects {
    padding-top: 2rem;
    width: 100%;
    height: 90%;
}

.bar {
    position: absolute;
    height: 100%;
    right: 0px;
    top: 0px;
    flex-grow: 1;
}

.main_normal {
    height: 100%;
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
}

.main_bar {
    height: 100%;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    opacity: 0.1;
}


